import { Injectable, OnDestroy } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, filter, map, Observable } from "rxjs";
import { IResponse } from "@shared/interfaces";
import { StorageService } from "@shared/services/storage.service";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { IUserRole } from "@shared/interfaces/user.interface";
import { HttpResponse } from '@angular/common/http';

export interface EmailCheckResponse {
  message: string;
  stackTrace: string;
}

@UntilDestroy()
@Injectable({ providedIn: 'root' })

export class AuthService implements OnDestroy {
  public loggedIn$: Observable<boolean>;
  private _loggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(this.loggedIn());
  constructor(
    private http: HttpClient,
    private storageService: StorageService,
  ) {
    this.loggedIn$ = this._loggedIn.asObservable().pipe(untilDestroyed(this));
  }

  public checkEmail(email: string): Observable<HttpResponse<EmailCheckResponse>> {
    return this.http.get<EmailCheckResponse>(`client/check-email?email=${email}`, { observe: 'response' });
  }

  public registration(data: { email: string, password: string, role: IUserRole }): Observable<any> {
    return this.http.post<IResponse>('client/register', data);
  }

  public confirmEmail(data: { token: string }): Observable<any> {
    return this.http.post<IResponse>('client/confirm-email', data);
  }

  public login(data: { email: string, password: string }): Observable<IResponse> {
    return this.http.post<IResponse>('client/login', data).pipe(
      map(response => {
        this.storageService.setItem('areas-token', response.authorization);
        this.updateAuthState();
        return response;
      })
    );
  }


  public logOut(): void {
    this.storageService.removeItem('areas-token');
    this.updateAuthState();
  }

  public loggedIn(): boolean {
    return !!this.storageService.getItem('areas-token');
  }

  public updateAuthState(): void {
    this._loggedIn.next(this.loggedIn());
  }


  ngOnDestroy(): void {
  }
}
