import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@api/auth.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { catchError, of, switchMap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'app-email-confirmed',
  templateUrl: './email-confirmed.component.html',
  styleUrls: ['./email-confirmed.component.scss'],
})
export class EmailConfirmedComponent implements OnInit {
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private auth: AuthService,
  ) { }

  ngOnInit(): void {
    this.route.paramMap
      .pipe(
        untilDestroyed(this),
        switchMap(params => {
          const token = params.get('token') || '';
          return this.auth.confirmEmail({ token }).pipe(
            catchError(error => {
              console.error('Email confirmation failed:', error);
              return of(null);
            })
          );
        })
      )
      .subscribe();
  }

  goBack(): void {
    this.router.navigate(['login']);
  }
}
