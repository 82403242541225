import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@core/guards/auth.guard';
import { UnAuthGuard } from '@core/guards/un-auth.guard';

const routes: Routes = [
  {
    path: '',
    // loadChildren: () => import("./main/main.module").then((m) => m.MainModule),
    pathMatch: 'full',
    redirectTo: 'catalog',
  },
  {
    path: 'confirm-email/:token',
    data: { showFooter: true },
    loadChildren: () => import('./pages/email-confirmed/email-confirmed.module').then(m => m.EmailConfirmedModule),
  },
  {
    path: 'auth',
    data: { showFooter: false },
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./pages/registration/registration.module').then(m => m.AuthModule),
  },
  {
    path: 'login',
    data: { showFooter: false },
    canActivate: [UnAuthGuard],
    loadChildren: () => import('./pages/log-in/log-in.module').then(m => m.LogInModule),
  },
  {
    path: 'catalog',
    data: { showFooter: true },
    loadChildren: () => import('./pages/catalog/catalog.module').then(m => m.CatalogModule),
  },
  {
    path: 'dashboard',
    data: { showFooter: false },
    loadChildren: () => import('./pages/dashboard/dashboard-page.module').then(m => m.DashboardPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'catalog-item',
    data: { showFooter: true },
    loadChildren: () => import('./pages/catalog-item/catalog-item.module').then(m => m.CatalogItemModule),
  },
  {
    path: 'privacy-policy',
    data: { showFooter: true },
    loadChildren: () => import('./pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule),
  },
  {
    path: 'terms-and-conditions',
    data: { showFooter: true },
    loadChildren: () => import('./pages/terms/terms.module').then(m => m.TermsModule),
  },
  // {
  //   path: 'owner',
  //   data: {showFooter: true},
  //   loadChildren: () => import("./owner-page/owner-page.module").then((m) => m.OwnerPageModule),
  //   // canActivate: [AuthGuard]
  // },
  { path: '**', redirectTo: 'catalog', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
