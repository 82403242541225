<footer class="footer-wrapper">
  <div class="footer-content">
    <div class="footer-left">
      <div class="logo">
        <img src="./assets/icons/AREAS.svg" alt="AREAS Logo" />
      </div>
      <div class="description description-pc">
        <p>
          {{ 'app.footer.areasDescription_1' | transloco }}
        </p>
        <p>
          {{ 'app.footer.contactUsAt' | transloco }}
        </p>
        <p>
          {{ 'app.footer.areasDescription_2' | transloco }}
        </p>
      </div>
    </div>
    <div class="footer-right">
      <div class="menu-row">
        <div
          *ngFor="let item of footerMenu"
          class="menu-section"
          [ngClass]="{ 'contact-us': item.title === 'Contact us', products: item.title === 'Products' }">
          <div class="menu-title">
            {{ item.title | transloco }}
          </div>
          <ul class="menu-items" [ngClass]="{ center: item.title === 'Contact us' }">
            <li *ngFor="let el of item.menuItems">
              <a routerLink="{{ el.link }}" *ngIf="!el.type">{{ el.name | transloco }}</a>
              <ng-container *ngIf="el.type" [ngSwitch]="el.type">
                <a class="link-with-icon" *ngSwitchCase="'email'" href="mailto:{{ el.link }}">
                  <img src="/assets/icons/mail.svg" alt="mail" />
                  <span>{{ el.name }}</span>
                </a>
                <a class="link-with-icon" *ngSwitchCase="'phone'" href="tel:{{ el.link }}">
                  <img src="/assets/icons/phone.svg" alt="phone" />
                  <span>{{ el.name }}</span>
                </a>
              </ng-container>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <span class="description-mob">
      {{ 'app.footer.areasDescription_2' | transloco }}
    </span>
  </div>
</footer>
